import React from "react"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import OfficeAddress from "./OfficeAddress"
import { Link } from "gatsby"
import Arrow from "../../svg/Arrow"
import FigureBox, { boxFormats } from "../../components/FigureBox"

class OfficePreview extends React.Component {
  render() {
    let { office } = this.props

    let LinkElement = Link
    let attributes = {}

    // link to email
    if (this.props.linkToSite && office.officeWebSite) {
      LinkElement = `a`
      attributes = {
        ...{
          target: `_blank`,
          href: `${office.officeWebSite}`,
        },
      }
    } else {
      attributes = {
        ...{
          to: `/about/office/${office.slug}`,
        },
      }
    }

    return (
      <div className={`office__preview row ${this.props.orientation}`}>
        {office.officeImage && (
          <Fade bottom delay={50} distance="25%">
            <figure
              className={`office__preview__figure ${
                this.props.figureClassName
              }`}
            >
              <LinkElement {...attributes} className="btn__arrow">
                <FigureBox
                  source={office.officeImage}
                  format={this.props.boxFormats}
                />
              </LinkElement>
            </figure>
          </Fade>
        )}

        <aside
          className={`office__preview__infos ${this.props.infosClassName}`}
        >
          <Fade bottom delay={100} distance="25%">
            <div className="inner">
              <p className="h3">
                <LinkElement {...attributes} className="btn__arrow">
                  {office.officeName}
                  <Arrow />
                </LinkElement>
              </p>
              {this.props.showAddress && (
                <OfficeAddress
                  office={office}
                  displayName={false}
                  columns="col-12"
                />
              )}
              {this.props.showAbout &&
                office.officeAbout && (
                  <div
                    className="office__preview__infos__about"
                    dangerouslySetInnerHTML={{
                      __html: office.officeAbout.childMarkdownRemark.html,
                    }}
                  />
                )}
            </div>
          </Fade>
        </aside>
      </div>
    )
  }
}

OfficePreview.defaultProps = {
  infosClassName: `col-12 col-md-5`,
  figureClassName: `col-12 col-md-7`,
  boxFormat: boxFormats.sixteenByNine,
  showAddress: true,
  showAbout: false,
  linkToSite: false,
}

OfficePreview.propTypes = {
  office: PropTypes.object.isRequired,
  orientation: PropTypes.string.isRequired,
  linkToSite: PropTypes.bool,
}

export default OfficePreview
