import React from 'react'

import { Link, graphql } from "gatsby"
import { withBreakpoints } from "react-breakpoints"
import Fade from "react-reveal/Fade"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// redux
import { invertHeader, resetHeader } from "../actions"
import { connect } from "react-redux"

import Layout from '../components/layout'
import SEO from '../components/seo'

// components
import OfficePreview from "../content/offices/OfficePreview"
import ContactPreview from "../content/contacts/ContactPreview"
import { boxFormats } from "../components/FigureBox"
import Text from "../content/texts/Text"

class AboutPage extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.invertHeader()
  }

  componentWillUnmount() {
    this.props.resetHeader()
  }
  
  officesNav = () =>
    this.props.data.offices.edges.map((office, index) => (
      <Link
        key={index}
        to={`/about/office/${office.node.slug}`}
        className="nav-item"
      >
        {office.node.officeName}
      </Link>
    ))

  offices = () =>
    this.props.data.offices.edges.map((office, index) => (
      <OfficePreview
        key={index}
        ref={`office-preview-${office.node.slug}`}
        office={office.node}
        orientation="orientation__row"
        boxFormats={
          this.props.screenWidth <= 1168
            ? boxFormats.square
            : boxFormats.postalCard
        }
      />
    ))

  ventures = () =>
    this.props.data.ventures.edges.map((office, index) => (
      <div
        key={index}
        ref={`venture-preview-${office.node.slug}`}
        className="col-12 col-md-4"
      >
        <OfficePreview
          linkToSite={true}
          office={office.node}
          showAddress={false}
          showAbout={true}
          orientation="orientation__col"
          infosClassName="col-12"
          figureClassName="col-12"
          boxFormats={boxFormats.portrait}
        />
      </div>
    ))

  clientsGrid = () =>
    this.props.data.clients.edges.map((client, index) => (
      <article key={index} className="client__logo">
        <Fade speed={450} delay={25 * index}>
          <figure>
            <GatsbyImage image={getImage(client.node.brandLogo)} alt={client.node.brandName} />
          </figure>
        </Fade>
      </article>
    ))

  clientsList = () => {
    let elements = []

    this.props.data.clients.edges.map((client, index) => {
      if (client.node.work) {
        elements.push(
          <Link to={`/work/brand/${client.node.slug}`} key={index}>
            {client.node.brandName}
          </Link>
        )
      }
    })

    return elements
  }

  contacts = () =>
    this.props.data.contacts.edges.map((contact, index) => (
      <div key={index} onMouseEnter={e => this.handleSlideTo(e, index + 1)}>
        <ContactPreview contact={contact} />
      </div>
    ))

  render() {
    return (
    <Layout>
        <SEO 
        title={`About us`} 
        description={`What we stand for and where we do it.`} 
        seoFields={this.props.data.seoFields}
        />
        <section className="about_page">

          {/* main header */}
        <Fade speed={450} delay={125}>
          <header className="about_page__header">
            <Text
              text={this.props.data.aboutIntro}
              breakTextAnimation={true}
              textWrapperTag={`h3`}
              responsiveText={true}
              contentClassName="col-12 col-md-10 col-lg-11 col-xl-10 mx-auto"
            />
          </header>
        </Fade>

        {/* office list */}
        <div className="offices__list">
          {/* intro header with office nav */}
          <div className="container">
            <header className="offices__list__header row mb-md-5 mt-5">
              <hgroup className="col-12 col-lg-3 col-xl-2">
                <h2>Offices</h2>
              </hgroup>
              <hgroup className="col-12 col-md-8 col-lg-9 mb-md-5">
                <Text
                  text={this.props.data.aboutOfficesIntro}
                  container={false}
                />
              </hgroup>
              <hgroup className="col-12 d-none d-md-block">
                <nav className="nav offices__list__nav">
                  {this.officesNav()}
                </nav>
              </hgroup>
            </header>
          </div>

          {/* offices list */}
          <div className="container-fluid mb-5">
            <div className="offices__list__offices">{this.offices()}</div>
          </div>
        </div>

        {/* ventures list */}
        {/* <div className="container-fluid mb-lg-5 pl-lg-5 pr-lg-5 mb-5">
          <div className="offices__list__ventures row">
            <aside className="col-12 mb-1">
              <p className="h3">Other Ventures</p>
              <hr />
              <Text
                text={this.props.data.aboutVenturesIntro}
                contentClassName="pr-4"
                container={false}
              />
            </aside>

            <aside className="offices__list__ventures__offices col-12">
              <div className="row">{this.ventures()}</div>
            </aside>
          </div>
        </div> */}

        {/* clients list */}
        <div className="container-fluid clients mb-5 pl-lg-5 pr-lg-5">
          <div className="row">
            <header className="col-12 mb-1 no-padding">
              <aside className="col-12 col-md-4 col-xl-4 h3">
                Our Partners
              </aside>
              <aside className="col-12 col-md-8 col-xl-8">
                <Text
                  text={this.props.data.aboutClientsText}
                  container={false}
                  contentClassName="col-12"
                />
              </aside>
              <div className="col-12">
                <hr />
              </div>
            </header>
            <section className="col-12 no-padding">
              <aside className="col-12 clients__grid">
                {this.clientsGrid()}
              </aside>
            </section>
          </div>
        </div>

        {/* end page text */}
        {this.props.data.aboutFooterText && (
          <div className="about_page__texts about_page__footer_text mb-5">
            <Text
              text={this.props.data.aboutFooterText}
              breakTextAnimation={true}
              textWrapperTag={`h2`}
              responsiveText={true}
              contentClassName="col-12 col-md-10 col-lg-8 mx-auto pt-1 pb-1 pt-lg-5 pb-lg-5"
            />
          </div>
        )}
        </section>
        </Layout>
    )
  }
}

export default connect(
    null,
    {
      invertHeader,
      resetHeader,
    }
  )(AboutPage)

export const query = graphql`
  query AboutQuery {
    offices: allContentfulOffices(
      sort: { fields: [order], order: ASC }
      filter: { node_locale: { eq: "en-US" }, venture: { eq: false } }
    ) {
      edges {
        node {
          ...officeFragmentBase
        }
      }
    }
    clients: allContentfulClients(
      sort: { fields: [brandName], order: ASC }
      filter: { visibleInClientsList: { eq: true } }
    ) {
      edges {
        node {
          id
          slug
          brandName
          brandLogo {
            gatsbyImageData(width: 120)
          }
          work {
            id
          }
        }
      }
    }
    ventures: allContentfulOffices(
      filter: { node_locale: { eq: "en-US" }, venture: { eq: true } }
    ) {
      edges {
        node {
          ...officeFragmentBase
        }
      }
    }
    seoFields: contentfulSeoFields(slug: { eq: "about-seo" }) {
      ...seoFieldsFragment
    }
    aboutIntro: contentfulTexts(slug: { eq: "about-intro-text" }) {
      ...textFragment
    }
    aboutTopImage: contentfulTexts(slug: { eq: "about-top-intro-image" }) {
      ...textFragment
    }
    aboutMidpageText: contentfulTexts(slug: { eq: "about-mid-page-text" }) {
      ...textFragment
    }
    aboutMidpageImages: contentfulTexts(slug: { eq: "about-mid-page-images" }) {
      ...textFragment
    }
    aboutFooterText: contentfulTexts(slug: { eq: "about-page-footer-text" }) {
      ...textFragment
    }
    aboutOfficesIntro: contentfulTexts(slug: { eq: "about-offices-intro" }) {
      ...textFragment
    }
    aboutVenturesIntro: contentfulTexts(slug: { eq: "about-ventures-intro" }) {
      ...textFragment
    }
    aboutClientsText: contentfulTexts(slug: { eq: "about-page-clients-text" }) {
      ...textFragment
    }
  }
`
